import {
  Box,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { Employee } from "../../models";
import { EmployeeStatus } from "../../models/employees/employeeStatus";
import { NavBackButton } from "../employees/NavBackButton";
import { SideDrawerTitle } from "../shared/SideDrawer";

type IssueCardsProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
};

export const IssueCards = ({
                             open,
                             handleComplete,
                             handleClose,
                             selectedEmployees
                           }: IssueCardsProps) => {
  const { config } = useConfig();
  const { employees, employer, cardOfferings } = useData();
  const { getToken } = useKindeAuth();
  const [error, setError] = useState("");
  const [employeesWithoutCard, setEmployeesWithoutCard] = useState<Employee[]>([]);
  const [selectedOfferingId, setSelectedOfferingId] = useState<string>(cardOfferings?.data?.[0]?.id || "");

  useEffect(() => {
    const filteredEmployees = selectedEmployees
      .filter(x => (x.cards == null || x.cards.length === 0) && x.status === EmployeeStatus.Active);
    setEmployeesWithoutCard(filteredEmployees);
    if (filteredEmployees.length === 0) {
      setError("Cards can only be issued to active users who do not already have one.");
    }
  }, [open, selectedEmployees]);

  const mutation = useMutation({
    mutationFn: async (employeeId: string) => {
      const response = await fetch(`${config?.API_URL}/employers/${employer?.data?.id}/cards?employeeId=${employeeId}&offeringId=${selectedOfferingId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({})
      });

      if (!response.ok) {
        throw new Error("There was a problem issuing the cards");
      }
    },
    onSuccess: () => {
      employees.refetch();
      toast.success("Cards have been issued successfully");
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (!selectedOfferingId) {
      setError("Please select a card offering.");
      return;
    }

    const batchSize = 10;
    for (let i = 0; i < employeesWithoutCard.length; i += batchSize) {
      const currentBatch = employeesWithoutCard.slice(i, i + batchSize);
      const promises = currentBatch.map(employee => mutation.mutateAsync(employee.id));
      await Promise.all(promises).catch(() => {
        setError('There was a problem issuing the cards');
      });
    }
  }

  return (
    <>
      <NavBackButton onBackPress={handleClose} />
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <SideDrawerTitle sx={{ paddingLeft: 2 }}>
            Issue {employeesWithoutCard.length > 1 ? "cards" : "a card"} for {employeesWithoutCard.length} {employeesWithoutCard.length > 1 ? "users" : "user"}
          </SideDrawerTitle>
        </Box>
        <Box padding={2}>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {!error && (
              <Grid item xs={12}>
                <InputLabel id="offering-label">Select a Card Offering</InputLabel>
                <Select
                  labelId="offering-label"
                  value={selectedOfferingId}
                  onChange={(event) => setSelectedOfferingId(event.target.value)}
                  fullWidth
                >
                  {cardOfferings?.data?.map((offering) => (
                    <MenuItem key={offering.id} value={offering.id}>{offering.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputLabel>{employeesWithoutCard.length > 1 ? "Cards" : "A card"} will be issued for {employeesWithoutCard.length} {employeesWithoutCard.length > 1 ? "users" : "user"}. Are you sure you want to proceed?</InputLabel>
            </Grid>
          </Grid>
        </Box>
        <Box padding={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            loading={mutation.isLoading}
            disabled={employeesWithoutCard.length === 0 || !selectedOfferingId}
          >
            Issue Cards for {employeesWithoutCard.length} Employee(s)
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
